import { action, computed, observable, runInAction } from "mobx";
import {
  finalForm,
  submitGuestData,
  uploadFiles,
} from "services/guest.service";

import { RootStore, StoreClassInterface } from "./RootStore";
export interface IError {
  email: string;
  password: string;
  confirmPassword: string;
}
export class StepsStore implements StoreClassInterface {
  @observable openModal: boolean = true;
  @observable currentStep: number = 0;
  @observable email: string = "";
  @observable projectName: string = "";
  @observable companyName: string = "";
  @observable comment: string = "";
  @observable projectNameError: boolean = false;
  @observable emailError: boolean = false;
  @observable files: Array<File> = [];
  @observable isLodaing: boolean = false;
  @observable finishSteps: boolean = false;
  @observable uploadFailed: boolean = false;
  @observable uploadError: string = "";
  @observable firstName: string = "";
  @observable lastName: string = "";
  @observable firstNameError = false;
  @observable lastNameError = false;

  readonly name = "StepsStore";
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setOpenModal = (value: boolean) => {
    this.openModal = value;
  };

  // @computed get isEmailValid(): boolean {
  //     if (this.email.length === 0) return false;
  //     return this.validateEmail(this.email);
  // }

  validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
  };

  @action setCurrentStep = (value: number) => {
    if (this.projectName.length === 0) {
      return this.setProjectNameError(true);
    }
    this.setProjectNameError(false);
    this.currentStep = value;
  };
  @action setFirstNameError = (value: boolean) => {
    this.firstNameError = value;
  };
  @action setLastNameError = (value: boolean) => {
    this.lastNameError = value;
  };

  @action setProjectName = (value: string) => {
    this.projectName = value;
  };

  @action setFinishSteps = (value: boolean) => {
    this.finishSteps = value;
  };
  @action setComapnyName = (value: string) => {
    this.companyName = value;
  };
  @action setFirstName = (value: string) => {
    this.firstName = value;
  };
  @action setLastName = (value: string) => {
    this.lastName = value;
  };
  @action setProjectNameError = (value: boolean) => {
    this.projectNameError = value;
  };

  @action setEmail = (value: string) => {
    this.email = value;
  };

  @action setComment = (value: string) => {
    this.comment = value;
  };

  @action setEmailError = (value: boolean) => {
    this.emailError = value;
  };

  @action setFiles = (value: Array<File>) => {
    this.files = value;
  };

  @action resetModal = () => {
    this.openModal = true;
    this.finishSteps = false;
    this.email = "";
    this.projectName = "";
    this.companyName = "";
    this.comment = "";
    this.files = [];
    this.currentStep = 0;
    this.firstName = "";
    this.lastName = "";
  };
  @action handleGuestData = async () => {
    if (this.email.length === 0 || this.validateEmail(this.email))
      return (this.emailError = true);
    this.isLodaing = true;
    let res = await submitGuestData(
      this.email,
      this.projectName,
      this.companyName,
      this.comment,
      this.firstName,
      this.lastName
    );
    const id = res.data.id;
    const pid = res.data.pid;
    debugger;
    let promiseArr: any = this.appendFiles(id, pid);
    // let response = await uploadFiles(appendFiles, id, pid);
    let response: any;
    await Promise.all(promiseArr).then((values) => {
      console.log(values);
      response = values;
    });
    debugger;
    let status = response.filter((x: any) => x.status === 0);
    debugger;
    if (status.length > 0) {
      this.uploadFailed = true;
    } else {
      await finalForm(id, pid, this.email);
      this.uploadFailed = false;
    }
    runInAction(() => {
      this.isLodaing = false;
      if (this.uploadFailed) {
        this.setCurrentStep(2);
        this.uploadError = status[0].data.response.data.error;
      } else {
        this.finishSteps = true;
      }
    });
  };

  @action appendFiles = (uuid: string, pid: string) => {
    const formUpload = new FormData();
    let promiseArr: any = [];
    this.files.map((e, index) => {
      formUpload.append("file", e);
      const myPromise = new Promise((resolve, reject) => {
        uploadFiles(formUpload, uuid, pid)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
      promiseArr.push(myPromise);
      return myPromise;
    });
    return promiseArr;
  };
}
