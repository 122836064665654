import { guestAxiosInstance } from "utils/axios.instance";

interface IResponse {
  message: string;
  status: number;
  data: any;
}

export const submitGuestData = async (
  email: string,
  projectname: string,
  companyName: string,
  description: string,
  firstName: string,
  lastName: string
): Promise<IResponse> => {
  try {
    const response = await guestAxiosInstance({
      url: `/store`,
      method: "POST",
      data: {
        email,
        projectname,
        name: companyName,
        description,
        firstName,
        lastName,
      },
    });
    return {
      message: "OK",
      status: 1,
      data: response.data,
    };
  } catch (err) {
    return {
      message: "",
      status: 0,
      data: "",
    };
  }
};

export const uploadFiles = async (
  files: FormData,
  uuid: string,
  pid: string
): Promise<IResponse> => {
  try {
    files.append("uuid", uuid);

    files.append("type", "teaser");

    files.append("pid", pid);

    const response = await guestAxiosInstance({
      url: "/upload",
      method: "POST",
      data: files,
    });

    return {
      message: "OK",
      status: 1,
      data: response.data,
    };
  } catch (error) {
    return {
      message: "",
      status: 0,
      data: error as string,
    };
  }
};

export const finalForm = async (
  uuid: string,
  pid: string,
  email: string
): Promise<IResponse> => {
  try {
    const response = await guestAxiosInstance({
      url: "/completed",
      method: "POST",
      data: {
        uuid,
        pid,
        email,
      },
    });

    return {
      message: "OK",
      status: 1,
      data: response.data,
    };
  } catch (error) {
    return {
      message: "",
      status: 0,
      data: "",
    };
  }
};
