import { useStores } from "hooks";
import { observer } from "mobx-react-lite";
import React from "react";
import { uploadFiles } from "services/guest.service";
import Input from "../Input";
import { Icon } from "@iconify/react";

const StepThree = observer(() => {
  const { stepsStore } = useStores();
  const {
    email,
    comment,
    setEmail,
    setComment,
    emailError,
    uploadError,
    uploadFailed,
    setFirstName,
    setLastName,
    firstName,
    lastName,
    setFirstNameError,
    setLastNameError,
    firstNameError,
    lastNameError,
  } = stepsStore;

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };
  return (
    <div>
      {uploadFailed && (
        <div className="flex">
          <div className="pt-2">
            <Icon
              icon="charm:info"
              color="#d83b01"
              className="mr-2"
              width="16px"
              height="16px"
            />{" "}
          </div>
          <span className="text-red-600 font-semibold">{uploadError}</span>
        </div>
      )}
      <Input
        error={firstNameError && "Vorname wird benötigt"}
        value={firstName}
        label={"Vorname"}
        onBlur={() => {
          if (firstName.length === 0) {
            setFirstNameError(true);
          } else {
            setFirstNameError(false);
          }
        }}
        classes={"items-start mb-4"}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFirstName(e.target.value);
        }}
      />
      <Input
        error={lastNameError && "Nachname wird benötigt"}
        value={lastName}
        label={"Nachname"}
        classes={"items-start mb-4"}
        onBlur={() => {
          if (lastName.length === 0) {
            setLastNameError(true);
          } else {
            setLastNameError(false);
          }
        }}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setLastName(e.target.value);
        }}
      />
      <Input
        error={emailError && "Das E-Mail-Format ist nicht korrekt."}
        value={email}
        label={"E-Mail*"}
        classes={"items-start mb-4"}
        handleChange={handleEmailChange}
      />
      <div className="text-left">
        <div className="mb-3">
          <label className="block mb-2 text-sm font-bold text-gray-700 ">
            Nachricht
          </label>
          <textarea
            value={comment}
            className="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid
                        rounded
                        transition
                        ease-in-out
                        outline-0
                        m-0
                        text-left
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                         border-gray-400
                    "
            id="exampleFormControlTextarea1"
            rows={3}
            placeholder="Hinterlassen Sie uns gern zusätzlich noch eine Nachricht."
            onChange={(e: any) => handleCommentChange(e)}
          ></textarea>
        </div>
      </div>
    </div>
  );
});

export default StepThree;
