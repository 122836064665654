import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// ReactDOM.render(<App />, document.getElementById("wizard"));

export function openApplication() {
  document.body.style["overflow-y"] = "hidden";
  document.body.style.overflowY = "hidden";

  ReactDOM.render(<App />, document.getElementById("wizard"));
}
export function unmountComponentAtNode() {
  document.body.style["overflow-y"] = "auto";
  document.body.style.overflowY = "auto";
  ReactDOM.unmountComponentAtNode(document.getElementById("wizard"));
}

window.openApplication = openApplication;
window.unmountComponentAtNode = unmountComponentAtNode;
