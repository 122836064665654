import React from "react";
import { configure } from "mobx";
import { RootStore } from "../stores/RootStore";
import {StepsStore} from "../stores/StepsStore";

configure({
    enforceActions: "observed",
});

const rootStore = new RootStore();

export interface StoresContext {
    rootStore: RootStore;
    stepsStore: StepsStore;
}

export const Context: React.Context<StoresContext> = React.createContext({
    rootStore: rootStore,
    stepsStore: rootStore.stepsStore,
});
