import { unmountComponentAtNode } from 'index';
import { observer } from 'mobx-react-lite';
import { useStores } from '../hooks';
import Modal from './Modal';
import WizardBody from './steps/WizardBody';

const Index = observer(() => {
    const { stepsStore } = useStores();
 
  return (
      <div className='flex'>
        {/* <button className="text-3xl font-bold underline" onClick={()=>stepsStore.setOpenModal(!stepsStore.openModal) }>open modal</button>  */}
        <Modal showModal={stepsStore.openModal} setShowModal={()=>{}} >
            <WizardBody/>
        </Modal>     
      </div>
  );
})

export default Index;
