import { useStores } from "hooks";
import { observer } from "mobx-react-lite";


const ProgresBar = observer((props:any) => {
    const {steps} = props;
    const {stepsStore} = useStores();
    const {currentStep, setCurrentStep} = stepsStore;
    return(<div className="flex justify-center items-center mb-6 mt-6">
        {steps.map((el: any, index: any)=>{
            return(
                <>
                    <div key={index} onClick={()=>{setCurrentStep(el.stepNr - 1)}} className={`${(currentStep === el.stepNr -1 || el.stepNr - 1 < currentStep)  ? " bg-lime-500 text-white " : " text-gray-400 border-gray-400 "} cursor-pointer justify-center items-center circle-custom-30 flex text-xs border-50 border `}>
                        {el.stepNr - 1 < currentStep ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8002 1.2002L3.91324 10.8002L1.2002 8.08715" stroke="#F2F2F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> : 
                            el.stepNr
                        }
                    </div>
                    {index !== steps.length - 1 && 
                         <hr className={`${(currentStep > el.stepNr - 1 || currentStep === steps.length) && "border-t-lime-500  "} w-16 border-t `}/>
                    }
            </>

            )
        })}
    </div>)
})

export default ProgresBar;