import axios from "axios";

export const BASE_URL = `${window.uploadPath}`;
//export const BASE_URL = "https://qa.kundenportal.dealcircle.de/api";

export const guestAxiosInstance = axios.create({
  baseURL: `${BASE_URL}/guest/teaser`,
  withCredentials: false,
  headers: { "Access-Control-Allow-Origin": "*" },
});
