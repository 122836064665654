import React, { useState} from 'react'
import Dropzone from "react-dropzone";
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';


const StepTwo = observer(() => {
    const [error, setError] = useState(false)
    const {stepsStore} = useStores();
    const {files, setFiles} = stepsStore;

    const handleFileAccept = (value: any) => {
        if(value.length === 0) return setError(true)
        setError(false)
        let temp: Array<File>  = [];
        value.map((el: File) => {
          if(files.filter(x => x.name === el.name).length === 0){
            temp.push(el)
          }
          return el;
        })

        if(temp.length > 0) {
          setFiles([...files, ...temp])
        }
    }

    const handleRemoveFile = (index: any) => {
        const temp = [...files]
        temp.splice(index, 1);
        setFiles([...temp])
    }

    return(
    <div className='max-height'>
            <div className='mb-2'>
                {files.map((el: any, index: any) => {
                    return(<div className='p-4 rounded bg-gray-100 flex mb-2 items-center justify-between'>
                        <div className=''>
                            {/* <img href={fileIcon} alt="dealcircle file icon" /> */}
                                <svg width="74" height="60" viewBox="0 0 74 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M65.8414 57.5C65.8414 57.8315 65.6808 58.1495 65.3949 58.3839C65.109 58.6183 64.7213 58.75 64.317 58.75H9.43894C9.03465 58.75 8.64691 58.6183 8.36103 58.3839C8.07516 58.1495 7.91455 57.8315 7.91455 57.5V2.5C7.91455 2.16848 8.07516 1.85054 8.36103 1.61612C8.64691 1.3817 9.03465 1.25 9.43894 1.25H46.0243C46.9899 1.29297 47.9085 1.60522 48.628 2.135L64.7621 15.365C65.4082 15.955 65.789 16.7082 65.8414 17.5V57.5Z" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M47.5488 1.5625V15C47.5488 15.3315 47.7094 15.6495 47.9953 15.8839C48.2812 16.1183 48.6689 16.25 49.0732 16.25H65.4696" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                        </div>
                        <div className='mr-6 flex items-center cut-text'>
                            {el.name}
                        </div>
                        <div className='close-btn mt-2 cursor-pointer' onClick={()=>handleRemoveFile(index)}><Icon icon="ant-design:close-circle-outlined" width="24px" height="24px" /></div>
                    </div>)
                })}
            </div>
    
        <Dropzone
        onDrop={handleFileAccept}
        maxSize={104857600}
      >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragAccept,
              isDragReject
            }) => {
              const additionalClass = isDragAccept
              ? "accept"
              : isDragReject
              ? "reject"
              : "";
              return (
                <div
                  {...getRootProps({
                    className: `border-primary text-primary  px-4  py-4  border-2 border-dashed rounded bg-gray-100 text-4xl cursor-pointer flex items-center justify-center ${additionalClass}`
                  })}
                >
                  <input {...getInputProps()} />
                          {isDragActive ?  isDragReject ? <Icon icon="ic:baseline-image-not-supported" color="#d83b01" />
                              : <Icon icon="healthicons:i-documents-accepted" color="rgb(132 204 22)" /> : <Icon icon="akar-icons:plus" />}
                        </div>
                      );
                    }}
                  </Dropzone>
                    {
                    error &&  <div className='flex items-center mt-2 text-red-600 justify-end'>
                            <div ><Icon icon="charm:info" color="#d83b01" className='mr-2' width="16px" height="16px"/> </div>
                            <span>Bitte nutzen sie für den Upload eines der folgenden Formate: PDF, DOC, PPTX</span>
                        </div>
                    }
        </div>
    )
})
export default StepTwo;