import { useStores } from "hooks";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import CurrentStep from "./CurrentStep";
import ProgresBar from "./ProgresBar";

declare global {
    interface Window {
        unmountComponentAtNode: any;
        uploadPath: any
    }
  }
const WizardBody = observer((props: any) =>{
    const {stepsStore} = useStores();
    const {currentStep, handleGuestData, setCurrentStep, isLodaing, resetModal} = stepsStore;
    const [steps, setSteps] = useState([{
        title: "1. Bezeichnung",
        stepNr: 1,
        paragraph: "Bitte geben Sie einen Projektnamen und den Namen Ihres Unternehmens an. Den Namen des Targets müssen Sie an dieser Stelle nicht nennen.",
        // component: <StepOne />,
        projectName:"",
        companyName: "",
        error: null,
    }, {
        title: "2. Sicherer Upload über unseren Server",
        stepNr: 2,
        paragraph: "Bitte laden Sie hier den Teaser und ggfs. die Blacklist (Ausschlussliste) hoch. Bitte nutzen Sie die Formate PDF, DOC, oder PPTX",
        // component: <StepTwo handleStepChange={(val)=>setSteps(val)} />,
        files: [],
        error: null,
    }, {
        title: "3. E-Mail",
        stepNr: 3,
        paragraph: "Nach Einsendung Ihrer Unterlagen kontaktieren wir Sie per E-Mail. Hinterlassen Sie uns dazu Ihre E-Mail Adresse.",
        //component: <StepThree handleStepChange={(val)=>setSteps(val)} />,
        email: "",
        comments: "",
        error: null,
    }]);

    const handleReset = () => {
        stepsStore.resetModal()
        if(window.unmountComponentAtNode) {
            window.unmountComponentAtNode();
        }
    }
    if(stepsStore.finishSteps) 
    return(
        <div className="flex flex-col items-center">
            <div className="flex align-center items-center justify-center mb-4">
                <img src={"https://dealcircle.com/wp-content/wizard/build/static/media/radio-btn.9eb112080e7253d97db247386821f8ae.svg"} alt="btn"/>
            </div>
            <h3 className="font-bold text-3xl">
            Unterlagen erfolgreich übermittelt
            </h3>
           <p className="mt-12 mb-12">
            Vielen Dank für Ihre Unterlagen und das Vertrauen. Wir werden uns zeitnah per E-Mail bei Ihnen melden.
            </p>        
            <div className="p-4 bg-dealcircle-yellow text-black rounded font-semibold w-250" onClick={()=>handleReset()}>Schließen</div>

        </div>
        ) 
    return (
        <div>
            {isLodaing ? <div className="flex items-center flex-col justify-center custom-width">
                <div>
                    <img src={"https://dealcircle.com/wp-content/wizard/build/static/media/infinity-loader-small.bca9e4077bd454e3ca0c249bd764a67a.svg"} alt="dealcircle spinning logo" /> 
                </div>
                <span>
                    Daten werden verabeitet
                </span>
            </div> 
            : <div>
                <div className="flex items-start justify-between rounded-t flex-col">
                     <div className="flex justify-between w-full pb-4 flex-col-reverse">
                     <h3 className="text-3xl text-left">
                        Ihre Shortlist
                      </h3>
                      <button
                        className="p-1 text-gray-400 ml-auto bg-transparent border-0 float-right text-3xl"
                        onClick={() => handleReset()}
                        >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.5 0.499023L0.5 23.499M0.5 0.499023L23.5 23.499L0.5 0.499023Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                      </button>
                    </div>   
                        <p className="text-left">
                            Legen Sie in drei Schritten Ihre persönliche Shortlist an. Wir kümmern uns um’s Matching - einfach und kostenlos!
                        </p>
                    </div>
                <ProgresBar steps={steps} />
                <CurrentStep steps={steps}/>
                <div className="flex flex-col items-center mt-10">
                    {currentStep !== steps.length - 1 ? 
                        <div className="p-4 bg-dealcircle-yellow text-black rounded font-semibold w-250 cursor-pointer" onClick={()=> setCurrentStep(currentStep + 1)}>Weiter</div> : 
                        <div className="p-4 bg-dealcircle-yellow text-black rounded font-semibold w-250 cursor-pointer" onClick={()=>handleGuestData()}>Sicher übermitteln</div>
                    }
                    {currentStep > 0  &&
                        <div className="p-4 text-gray-500 rounded border-gray-500 border mt-4 font-semibold w-250 cursor-pointer" onClick={()=> setCurrentStep(currentStep - 1)}>Zurück</div>
                    }
                </div>
                </div>
                }
        </div>
    )
});

export default WizardBody;