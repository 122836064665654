import { useStores } from "hooks";
import { observer } from "mobx-react-lite";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const CurrentStep = observer((props: any) => {
     const {steps} = props;
     const { stepsStore } = useStores();
     const { currentStep } = stepsStore;
     const {title, paragraph, stepNr} = steps[currentStep];

    return(
        <div>
            <div className="text-left mb-8">
                <h4 className="text-3xl mb-2">
                    {title}
                </h4>
                <p>
                    {paragraph}
                </p>
            </div>
            <div>
                {currentStep === 0 && 
                    <StepOne />
                }
                 {currentStep === 1 && 
                    <StepTwo />
                }
                 {currentStep === 2 && 
                    <StepThree />
                }
            </div>
        </div>
    )
})

export default CurrentStep;