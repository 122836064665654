import './App.css';
import Index from './components/index.tsx';

function App() {
  return (
    <div className="App">
     <Index/>
    </div>
  );
}




export default App;
