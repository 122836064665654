import { StepsStore } from "./StepsStore";

export class RootStore {
    readonly stepsStore: StepsStore;

    constructor() {
        this.stepsStore = new StepsStore(this);
    }
}

export interface StoreClassInterface {
    readonly name: string;
}
