import React from "react"

const Input = (props: any) => {
  const { label, placeholder, autocomplete, handleChange, error, value, type, onBlur, name, disabled, classes, customClasses } = props
  return (
    <div className={`flex flex-col w-full ${classes ? classes : 'mb-8'}`}>
      {label && <label className="block mb-2 text-sm font-bold text-gray-700">{label}</label>}
      <input
      
        defaultValue={value}
        disabled={disabled}
        onBlur={onBlur}
        name={name}
        autoComplete={autocomplete}
        onChange={handleChange}
        className={`
        ${
          customClasses
            ? customClasses
            : `border ${
                error ? 'border-red-400' : 'border-gray-400'
              } rounded-md w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`
        }
        `}
        id={placeholder}
        type={type}
        placeholder={placeholder}
      />
      {error && <div className="pt-1 pl-2 text-sm text-red-400">{error}</div>}
    </div>
  )
}

export default Input