import { useStores } from "hooks";
import { observer } from "mobx-react-lite";
import React from "react";
import Input from "../Input"

const StepOne = observer(() =>{
    const {stepsStore} = useStores();
    const {projectName, companyName, projectNameError, setProjectName, setComapnyName} = stepsStore;
    
    const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectName(e.target.value)
    }
    const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComapnyName(e.target.value)
    }
    return(
        <div >
            <Input value={projectName} error={projectNameError && "Projektname wird benötigt"} label={"Projektname*"} classes={"items-start mb-4"} handleChange={(e: React.ChangeEvent<HTMLInputElement>)=> handleProjectNameChange(e)}  />
            <Input value={companyName} label={"Unternehmensname"} classes={"items-start mb-4"} handleChange={(e: React.ChangeEvent<HTMLInputElement>)=>handleCompanyNameChange(e)}  />
        </div>
    )
    
})
    export default StepOne;